/*----------------
margin space
-----------------*/

.mb0 {
  margin-bottom: 0px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb80 {
  margin-bottom: 80px;
}
.mb100 {
  margin-bottom: 100px;
}
.mt0 {
  margin-top: 0px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt60 {
  margin-top: 60px;
}
.mt80 {
  margin-top: 80px;
}
.mt100 {
  margin-top: 100px;
}
.mr0 {
  margin-right: 0px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 10px;
}
.ml0 {
  margin-left: 0px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}

/*----------------
less margin space from top
-----------------*/
.mt-20 {
  margin-top: -20px;
}
.mt-40 {
  margin-top: -40px;
}
.mt-60 {
  margin-top: -60px;
}
.mt-80 {
  margin-top: -80px;
}

/*---------------
Padding space
-----------------*/
.nopadding {
  padding: 0px;
}
.nopr {
  padding-right: 0px;
}
.nopl {
  padding-left: 0px;
}
.pinside10 {
  padding: 10px;
}
.pinside20 {
  padding: 20px;
}
.pinside30 {
  padding: 30px;
}
.pinside40 {
  /* padding: 40px; */
}
.pinside50 {
  padding: 50px;
}
.pinside60 {
  padding: 60px;
}
.pinside80 {
  padding: 80px;
}
.pinside100 {
  padding: 100px;
}
.pdt10 {
  padding-top: 10px;
}
.pdt20 {
  padding-top: 20px;
}
.pdt30 {
  padding-top: 30px;
}
.pdt40 {
  padding-top: 40px;
}
.pdt60 {
  padding-top: 60px;
}
.pdt80 {
  padding-top: 80px;
}
.pdb10 {
  padding-bottom: 10px;
}
.pdb20 {
  padding-bottom: 20px;
}
.pdb30 {
  padding-bottom: 30px;
}
.pdb40 {
  padding-bottom: 40px;
}
.pdb60 {
  padding-bottom: 60px;
}
.pdb80 {
  padding-bottom: 80px;
}
.pdl10 {
  padding-left: 10px;
}
.pdl20 {
  padding-left: 20px;
}
.pdl30 {
  padding-left: 30px;
}
.pdl40 {
  padding-left: 40px;
}
.pdl60 {
  padding-left: 60px;
}
.pdl70 {
  padding-left: 70px;
}
.pdl80 {
  padding-left: 80px;
}
.pdl120 {
  padding-left: 120px;
}
.pdr10 {
  padding-right: 10px;
}
.pdr20 {
  padding-right: 20px;
}
.pdr30 {
  padding-right: 30px;
}
.pdr40 {
  padding-right: 40px;
}
.pdr60 {
  padding-right: 60px;
}
.pdr80 {
  padding-right: 80px;
}
* {
  padding: 0;
  margin: 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.63);
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75) !important;
}
.btn:hover {
  color: #f7ff00 !important;
  text-decoration: none;
}
.prev-link-style {
  text-decoration: none;
}
a.next-certi-link:hover {
  color: #f7ff00 !important;
  text-decoration: none;
}
a.prev-link-style:hover {
  color: #f7f3f3 !important;
  text-decoration: none;
  background: #1c1c1c;
}
.card-round {
  border-radius: 25px;
  border: 4px solid #004f98 !important;
  padding: 20px;
}
.card-round-bg-white {
  background: #fff;
}
.card-round-bg-blue {
  background: #61dafb;
}
.whitebox {
  background: transparent;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding-top: 45px;
}
.text-blue {
  color: #004f98;
  font-weight: 600;
}
.whitebox2 {
  background: transparent;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding-top: 15px;
  border-radius: 16px;
}
.qr {
  background: #fff;
  width: 150px;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  padding-top: 0;
  border: 4px solid #004f98;
  border-radius: 16px;
}

.qr img {
  border-radius: 16px;
}

.homebg {
  background: #e1f0ff;
  min-height: 926px;
}
.color-nav {
  background-color: #004f98a1;
  box-shadow: 1px 1px 3px #000;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #f7ff00 !important;
}
.con-wabtn {
  line-height: 23px !important;
}
.banner {
  background: url(/images/bannerbg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.banner .combtn {
  margin-bottom: 15px;
  margin-right: 10px;
}

.banner .wrapper-container {
  padding-top: 19%;
}

.mrg-home {
  margin-bottom: 15px;
  color: #fff !important;
}

.combtn:hover {
  color: #ffffff !important;
}

.combtn {
  background-color: #056ece;
  color: #ffffff !important;
  text-align: center;
  border-color: #a7a7a7;
  border-radius: 16px;
  padding-left: 17px;
  padding-right: 17px;
  border: none;
  border: 1px solid #ffffff78;
  line-height: 13px;
  box-shadow: 1px 1px 3px #2d608e;
  font-weight: 600;
}

.white-logo {
  width: auto;
  height: 40px;
  margin: 0px;
}
.counter-box {
  display: block;
  padding: 40px 20px 37px;
  text-align: center;
  border: 1px solid #dddddd;
  background-color: #fff;
}

.counter-box p {
  margin: 5px 0 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}

.counter-box i {
  font-size: 60px;
  margin: 0 0 15px;
  color: #d2d2d2;
}

.counter {
  display: block;
  font-size: 32px;
  font-weight: 700;
  color: #666;
  line-height: 28px;
}

.counter-box.colored {
  background: #3acf87;
}

.counter-box.colored p,
.counter-box.colored i,
.counter-box.colored .counter {
  color: #fff;
}
.graybox {
  background: #f5f5f5;
}
.border-right {
  border-right: 1px solid #eee;
}
.main-section {
  padding: 20px;
  text-align: left;
}
.main-section .extrasmall {
  font-size: 10px;
}
.card-part {
  border-radius: 5px;
  margin: 0px;
  border: 1px solid #dddddd;
  background-color: #fff;
  padding-bottom: 20px;
}
.card-title h1 {
  margin: 0px;
  color: #000;
  font-size: 23px;
  padding: 15px 0px 8px 0px;
  text-align: left;
}
.card-description p {
  min-height: 10px;
  overflow: hidden;
  color: #848484;
  margin: 0px;
}
.card-cart a {
  border-radius: 0px;
  font-size: 11px;
}
.card-cart {
  padding-top: 10px;
}

.header-title h3 {
  font-size: 3vw;
  font-weight: 600;
  color: #004f98;
}
.bluelight-bg {
  background: #b8ddff;
}
.banner h1 {
  font-size: 7rem;
}
.banner h1,
.banner h5 {
  text-align: left;
}
.combtn1 {
  margin-bottom: 45px;
  /* margin-right: 40px; */
  padding: 15px 29px;
}
.offer {
  background: #fff;
  border: 1px solid #ddd;
  border-top-color: rgb(221, 221, 221);
  border-right-color: rgb(221, 221, 221);
  border-bottom-color: rgb(221, 221, 221);
  border-left-color: rgb(221, 221, 221);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin: 15px 0;
  overflow: hidden;
}
.offer-warning {
  border-color: #f0ad4e;
}
.offer-warning .shape {
  border-color: transparent #f0ad4e transparent transparent;
}
.shape {
  border-style: solid;
  border-width: 120px 120px 0 0;
  float: left;
  height: 0;
  width: 0;
  transform: rotate(180deg);
}
.shape-text {
  color: #fff;
  font-size: 37px;
  font-weight: 700;
  position: relative;
  white-space: nowrap;
  transform: rotate(132deg);
  right: -108px;
  top: -95px;
  -ms-transform: rotate(30deg);
}
.offer-content {
  padding: 10px;
  text-align: right;
}
.offer-content .lead {
  font-size: 21px;
  font-weight: 600;
  top: 15px;
  color: #000;
}
.offer-content span.pro-id {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
.Padd_edit {
  padding: 3vh 9vh;
}
.homebg .bg-transparent-overlay {
  /* background-image: url("../images/Tech.jpg"); */
  height: 400px;
  background-position: 50% -75.9584px;
  position: relative;
  color: #fff;
}
.navbar-transparent .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background: #000;
  opacity: 0.3;
  z-index: 0;
}

.homebg .Features .icon {
  color: #fff !important;
  background: #004f98 !important;
  font-size: 50px !important;
  border-radius: 50% !important;
  width: 90px !important;
  height: 90px !important;
  line-height: 90px !important;
}
